<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
     <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Mon profil</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Mon profil</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <iconComponent title="Informations personnelles" icone="fa fa-address-book-o" color="#ff4301" bgcolor="#ffd6c8"></iconComponent>
              <hr class="solid">
              <div class="is-size-6 has-text-weight-normal">
                <p class="subtitle"><span class="has-text-weight-semibold">Nom : </span>{{ mesinfos.name }}</p>
                <p class="subtitle"><span class="has-text-weight-semibold">Email :</span> {{ mesinfos.email }}</p>
                <div class="subtitle is-flex">
                  <div class="has-text-weight-semibold p-r-5">
                    Adresse :
                  </div>
                  <div>
                  {{ mesinfos.street }}<br>
                  {{ mesinfos.zip }} {{ mesinfos.city }}
                  </div>
                </div>
                <p class="subtitle">Pour modifier vos informations personnelles, merci de contacter le bureau des membres.</p>
              </div>
            </article>
          </div>

          <div class="tile is-parent">
            <article class="tile is-child box">
              <iconComponent title="Informations coopérateur" icone="fa-user-o" color="#7972ff" bgcolor="#e2dffe"></iconComponent>
              <hr class="solid">
              <div class="is-size-6 has-text-weight-normal has-text-centered">
                <p class="subtitle"><span class="has-text-weight-semibold">Numéro coopérateur :</span> {{ mesinfos.number }}</p>
                <p class="subtitle"><span class="has-text-weight-semibold">Membre depuis le :</span> {{ this.$moment(mesinfos.memberSince).format("DD/MM/YYYY") }}</p>
                <hr class="solid">
                <div class="has-text-centered m-t-15 m-b-15">
                  <p class="subtitle">Statut : <span style="color: #7972ff"> {{ status }} </span></p>
                  <p class="subtitle" v-if="volant">Compteur vacations : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
                  <div class="subtitle" v-else>
                    <p>Services anticipés : <span style="color: #7972ff"> {{ mesinfos.counter }} </span></p>
                    <p>Rattrapages : <span style="color: #7972ff"> {{ mesinfos.abcdCounter }} </span></p>
                  </div>
                  <p class="subtitle" v-if="mesinfos.type!='standard' && volant">{{ next_service }}</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { authComputed } from "@/store/helpers.js";
import statusMixin from '@/mixins/statusMixin.js'
const IconComponent = () => import("@/components/IconComponent");
export default {
  name: "Infos",
  data: function() {
    return {
      mesinfos: "",
      volant: ""
    };
  },
  mixins: [statusMixin],
  //props: ["mesinfos"],
  computed: {
    ...authComputed
  },
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.volant = this.mesinfos.currentTeam.includes("volante")
    window.scrollTo(0,0);
  },
  components: {
    IconComponent,
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
  margin-top:  1rem;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.w50 {
  width: 50px;
  display: inline-block;
  margin: 1px;
}

.sub-content {
  margin-left: 12px;
}
</style>
